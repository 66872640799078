export const functionalityPermission = {
  NOTIFICATION: {
    id: 1,
    name: 'Notificações',
    permissions: {
      list: { id: 89, permissionName: 'Ver notificações' },
      create: { id: 101, permissionName: 'Criar Notificação' }
    }
  },
  CAMPAIGN: {
    id: 2,
    name: 'Calculadora',
    permissions: {
      list: { id: 90, name: 'Acessar campanhas' },
      create: { id: 91, name: 'Criar campanha' },
      edit: { id: 92, name: 'Pausar/Reativar campanha' },
      delete: { id: 93, name: 'Excluir campanha programada' }
    }
  },
  PROFILE: {
    id: 3,
    name: 'Perfil',
    permissions: {
      editSimple: { id: 98, name: 'Editar informações básicas' },
      edit: { id: 99, name: 'Editar informações adicionais' },
      remove: { id: 100, name: 'Descadastrar conta' }
    }
  },
  USER: {
    id: 4,
    name: 'Usuários',
    permissions: {
      list: { id: 94, name: 'Acessar moderação de usuários' },
      register: { id: 95, name: 'Solicitar Cadastro (será desenv)' },
      approve: { id: 96, name: 'Aprovar/recusar usuários' },
      edit: { id: 97, name: 'Editar perfil de usuário (será desenv)' }
    }
  }
}

export const pagesNames = {
  NOTIFICATION: {
    id: 1,
    name: 'Notificações',
    code: 'notification',
    permissions: { list: 'list', create: 'create' }
  },
  CAMPAIGN: {
    id: 2,
    name: 'Calculadora',
    code: 'campaign',
    permissions: { list: 'list', create: 'create', edit: 'edit', delete: 'delete' }
  },
  PROFILE: {
    id: 3,
    name: 'Perfil',
    code: 'profile',
    permissions: { editSimple: 'editSimple', edit: 'edit', remove: 'remove' }
  },
  USER: {
    id: 4,
    name: 'Usuários',
    code: 'user',
    permissions: {
      approve: 'approve',
      edit: 'edit',
      inactive: 'inactive',
      list: 'list',
      register: 'register'
    }
  }
}
